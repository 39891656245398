import React from "react";
import { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";

import Hello from "./App/Hello";
import Item from "./App/Item";

import Text from './App/Text'
import Audio from './App/Audio'
import Video from './App/Video'

import Container from 'react-bootstrap/Container';

class App extends Component {

  render() {

    return (
      <>
        <header>
          <Helmet>
            <html lang="ru" />
            <meta charSet="utf-8"/>
            <link rel="icon" href="/favicon.ico"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta name="theme-color" content="#000000"/>
            <link rel="apple-touch-icon" href="/logo192.png"/>
            <link rel="manifest" href="/manifest.json" />
            <meta name="description" content="" />
            <title>Музей-квартира И.Д. Сытина</title>
          </Helmet>
        </header>
          <Container>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Hello />} />
                <Route path="/i/:id" element={<Item />}/>
                <Route path="/i/:id/text" element={<Text />}/>
                <Route path="/i/:id/audio" element={<Audio />}/>
                <Route path="/i/:id/video" element={<Video />}/>
                <Route path="*" element={<Hello />}/>
              </Routes>
            </BrowserRouter>
        </Container>
      </>
    )

  }

}

export default App;