import React, {Component} from "react";
import withRouter from './withRouter';

import Image from 'react-bootstrap/Image';

class NotFound extends Component {

    render() {

        return (
            <div className="prevent-select d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <Image src="/images/icons/link-broken.svg" className="mb-4" width={50} />
                    <h5 className="text-bold">Ссылка недействительна</h5>
                    <p className="">Информация об объекте была удалена или ошибка при вводе адреса</p>
                </div>
            </div>
        )

    }
}

export default withRouter(NotFound);


