import React from 'react';
import ReactDOM from 'react-dom';
//import { hydrateRoot } from 'react-dom/client';
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/index.scss"



ReactDOM.hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  document.getElementById('root')
);


/*
const container = document.getElementById('root');
const root = hydrateRoot(container, <React.StrictMode><App /></React.StrictMode>);
*/