import React, {Component} from "react";
import { Link } from 'react-router-dom';
import { global } from './../config.js';
import withRouter from './withRouter';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import NotFound from './NotFound'
import NotConnect from './NotConnect'
import Loading from './Loading'

const urlTypes = [
    {
        name: "Мультимедиа гид",
        type: "artefact",
        logo: "/images/icons/ar_logo.svg",
        icon: "/images/icons/text.svg"
    },
    {
        name: "Гид по музею",
        type: "izitravel",
        logo: "/images/icons/izitravel_logo.svg",
        icon: "/images/icons/text.svg"
    }
]

class Item extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.params.id,
            item: {},
            loading: true,
            error: false,
            notConnect: false
        };

        this.item = this.item.bind(this)

    }

    componentDidMount(){
        this.item()
    }

    item() {
        
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        fetch(global.endpoint_api + '/items/' + this.state.id, requestOptions)
        .then(res => res.json().then(
            (result) => {

                if (res.status === 200 && !result.error) {
                    this.setState({
                        item: result,
                        loading: false,
                        title: result.title,
                        uid:  result.uid,
                        isActive: result.isActive
                    });
                } else {
                    if (result.error) {
                        this.setState({
                            error: result.error.message
                        });
                    } else {
                        this.setState({
                            error: "Произошла ошибка. Пожалуйста, попробуйте позже."
                        });
                    }
                }
                
                this.setState({
                   loading: false
                });
            }
        )).catch(err => {
            console.log(err)
            this.setState({
                notConnect: true
            });
        })

    }

    render() {

        let { item, loading } = this.state

        if (this.state.notConnect)
            return (<NotConnect />)

        return (
            <>
            <Row className="justify-content-md-center">
                <Col md={9} lg={6}>
                
                    {loading && (<Loading />)}
                    
                    {!item.title && !loading && (<NotFound />)}

                    {item.title && !loading && (
                        <>
                            <div className="d-flex mt-3" aria-hidden="true">
                                <Image src="/logo192.png" className="mb-3" width={24} alt="Логотип музея-квартиры И. Д. Сытина" />
                                <p className="text-small main-color ms-2">Музей-квартира И. Д. Сытина</p>
                            </div>
                            
                            <div className="mt-3 mb-5 pb-3">

                                <div className="mb-4">
                                    <h1 className="h2 text-bold-extra">{item.title}</h1>
                                    {item.photo != null && (
                                        <Image className="cover-preview-public mt-3 mb-2" src={item.photo} alt={"Фотография " + item.title} />
                                    )}
                                </div>
                                            
                                {Object.keys(urlTypes).map(function(key) {
                                    let search = item.urls.findIndex(x => x.type === urlTypes[key].type)
                                    if (search === -1)
                                        return (<span key={key}></span>)
                                    return (
                                        <Link to={item.urls[search].url} key={key} target="_blank" className="no-link">
                                            <div className="card card-simple">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-start justify-content-top">
                                                        <Image src={urlTypes[key].icon} width={40} alt={"Иконка"} aria-hidden="true"/>
                                                        <div className="ms-3">
                                                            <p className="text-bold h5 mb-1" aria-hidden="true"><Image src={urlTypes[key].logo} width={100} className="" alt={"Логотип сайта " + urlTypes[key].type} /></p>
                                                            <p className="m-0 truncate-text-lines"><nobr>{urlTypes[key].name}</nobr></p>
                                                        </div>
                                                        <div className="w-100 text-end" aria-hidden="true">
                                                            <nobr>
                                                            <span className="me-2">РУС</span>
                                                            <span>ENG</span>
                                                            {/*
                                                            <Image src="/images/icons/russian.svg" className="me-2" width={20}/>
                                                            <Image src="/images/icons/english.svg" width={20}/>
                                                            */}
                                                            </nobr>
                                                            
                                                        </div>
                                                    </div>
                                                    {/*
                                                    <div className="mb-2">
                                                        <Image src={urlTypes[key].logo} width={100} className="mb-3" />
                                                        <p className="m-0">{urlTypes[key].name}</p>
                                                    </div>
                                                    */}
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}

                                {item.video !== null && (
                                    <Link to={"/i/" + item.uid + "/video"} className="no-link">
                                        <div className="card card-simple">
                                            <div className="card-body">
                                                <div className="d-flex align-items-start">
                                                    <Image src="/images/icons/video.svg" width={40} alt="Иконка" aria-hidden="true"/>
                                                    <div className="ms-3">
                                                        <p className="text-bold h5 mb-1">Русский жестовый язык с субтитрами</p>
                                                        <p className="m-0 truncate-text-lines" aria-hidden="true">Видео</p>
                                                    </div>
                                                </div>
                                                {/*
                                                <div className="">
                                                    <h5 className=""><Image src="/images/icons/video.svg" width={28}/> Видео</h5>
                                                    <p className="m-0">Русский жестовый язык с субтитрами</p>
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                    </Link>
                                )}

                                {item.audio !== null && (
                                    <Link to={"/i/" + item.uid + "/audio"} className="no-link">
                                        <div className="card card-simple">
                                            <div className="card-body">
                                                <div className="d-flex align-items-start">
                                                    <Image src="/images/icons/audio.svg" width={40} alt="Иконка" aria-hidden="true"/>
                                                    <div className="ms-3">
                                                        <p className="text-bold h5 mb-1">Тифлокомментирование</p>
                                                        <p className="m-0 truncate-text-lines" aria-hidden="true">Аудио</p>
                                                    </div>
                                                </div>
                                                {/*
                                                <div className="">
                                                    <h5 className=""><Image src="/images/icons/audio.svg" width={20}/> Аудио</h5>
                                                    <p className="m-0">Тифлокомментарии</p>
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                    </Link>
                                )}

                                {item.text !== null && (
                                    <Link to={"/i/" + item.uid + "/text"} className="no-link">
                                        <div className="card card-simple">
                                            <div className="card-body">
                                                <div className="d-flex align-items-start">
                                                    <Image src="/images/icons/text.svg" width={40} alt="Иконка" aria-hidden="true"/>
                                                    <div className="ms-3">
                                                        <p className="text-bold h5 mb-1">Ясный язык</p>
                                                        <p className="m-0 truncate-text-lines" aria-hidden="true">Текст</p>
                                                    </div>
                                                </div>
                                                {/*
                                                <div className="">
                                                    <h5 className=""><Image src="/images/icons/text.svg" width={20}/> Текстовое описание</h5>
                                                    <p className="m-0">Подробная информация</p>
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                    </Link>
                                )}

                                
                            </div>
                            
                        </>
                    )}
                    
                </Col>
            </Row>
            </>
        )

    }
}

export default withRouter(Item);