import React, {Component} from "react";
import withRouter from './withRouter';

import Image from 'react-bootstrap/Image';

class Hello extends Component {

    render() {

        return (
            <div className="prevent-select d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <Image src="/logo192.png" className="mb-3" width={72} />
                    <h4 className="text-bold">Музей-квартира<br />И. Д. Сытина</h4>
                    <p className="small"><a href="https://sytin-museum.ru/">sytin-museum.ru</a></p>
                </div>
            </div>
        )

    }
}

export default withRouter(Hello);