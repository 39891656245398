import React, {Component} from "react";
import { Link } from 'react-router-dom';
import { global } from './../config.js';
import withRouter from './withRouter';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import NotFound from './NotFound'
import NotConnect from './NotConnect'
import Loading from './Loading'

class Audio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.params.id,
            item: {},
            loading: true,
            error: false,
            notConnect: false,
            currentTrack: -1,
            autoPlay: false,
            playing: false,
        };

        this.item = this.item.bind(this)
        this.handleClickNext = this.handleClickNext.bind(this)
        this.handleClickPreviuos = this.handleClickPreviuos.bind(this)
        this.playAudio = this.playAudio.bind(this)
    }

    componentDidMount(){
        this.item()
    }

    item() {
        
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        fetch(global.endpoint_api + '/items/' + this.state.id, requestOptions)
        .then(res => res.json().then(
            (result) => {

                if (res.status === 200 && !result.error) {
                    this.setState({
                        item: result,
                        loading: false
                    });
                } else {
                    if (result.error) {
                        this.setState({
                            error: result.error.message
                        });
                    } else {
                        this.setState({
                            error: "Произошла ошибка. Пожалуйста, попробуйте позже."
                        });
                    }
                }
                this.setState({
                    loading: false
                });
            }
        )).catch(err => {
            console.log(err)
            this.setState({
                notConnect: true
            });
        })

    }

    handleClickNext() {
        let currentTrack = this.state.currentTrack < this.state.item.audio.length - 1 ? this.state.currentTrack + 1 : 0
        console.log(currentTrack)
        this.setState({
            currentTrack
        })
    }

    handleClickPreviuos() {
        let currentTrack = this.state.currentTrack > 0 ? this.state.currentTrack - 1 : this.state.item.audio.length - 1
        console.log(currentTrack)
        this.setState({
            currentTrack
        })
    }

    playAudio = () => {
        if (!this.state.autoPlay)
            this.setState({autoPlay: true})
        document.getElementsByClassName("rhap_play-pause-button")[0].click()
    };


    render() {

        let { item, loading, currentTrack } = this.state
        let self = this

        if (this.state.notConnect)
            return (<NotConnect />)

        return (
            <>
                {item.audio && item.audio !== null && !loading && (
                    <Row className="justify-content-md-center mb-4 mt-3">
                        <Col md={9} lg={6}>
                            <Link to={"/i/" + this.state.id}><span aria-hidden="true">&larr;</span> Назад</Link>
                        </Col>
                    </Row>
                )}
                <Row className="justify-content-md-center mb-4">
                    
                    <Col md={9} lg={6}>
                        
                        {loading && (<Loading />)}

                        {!item.audio && !loading && (<NotFound />)}

                        {item.audio && item.audio !== null && !loading && (
                            <div className="mb-5">
                                <div className="mb-4">
                                    <div className="d-flex">
                                        <Image src="/images/icons/audio.svg" width={40} aria-hidden="true"/>
                                        <div className="ms-3">
                                            <h5 className="mb-1">Тифлокомментирование</h5>
                                            <p className="m-0 truncate-text-lines" aria-hidden="true">Аудио</p>
                                        </div>
                                    </div>
                                    <div className="">

                                            {item.audio.length > 0 && (
                                                <AudioPlayer
                                                    ref={this.player}
                                                    src={item.audio[this.state.currentTrack < 0 ? 0 : this.state.currentTrack].fileLocation}
                                                    showSkipControls
                                                    showJumpControls={false}
                                                    onPause={() => this.setState({playing: false})}
                                                    onPlay={e => {if (this.state.currentTrack < 0) this.setState({currentTrack: 0});this.setState({playing: true})}}
                                                    autoPlayAfterSrcChange={true}
                                                    className="mt-4"
                                                    onClickNext={this.handleClickNext}
                                                    onClickPrevious={this.handleClickPreviuos}
                                                    autoPlay={this.state.autoPlay}
                                                    i18nAriaLabels={
                                                        {
                                                            player: "аудиоплеер",
                                                            progressControl: "индикатор загрузка",
                                                            volumeControl: "уровень громкости",
                                                            play: "воспроизвести",
                                                            pause: "пауза",
                                                            rewind: "000",
                                                            forward: "000",
                                                            previous: "предыдущая аудиозапись",
                                                            next: "следующая аудиозапись",
                                                            volume: "громкость",
                                                            volumeMute: "отключить звук",
                                                        }
                                                    }
                                                />
                                            )}
                                        </div>

                                        {item.audio.length > 0 && (
                                            <div className="playlist">
                                                {Object.keys(item.audio).map(function(key) {
                                                    return (
                                                        <div key={key} className={"track" + (parseInt(currentTrack) === parseInt(key) ? " playing" : "")} onClick={(e) => {self.setState({currentTrack: parseInt(key)}, () => {if (!self.state.playing) self.playAudio()})}}>
                                                            <div className="d-inline-block track__name">
                                                                <p className="mb-0 text-truncate">{item.audio[key].title}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default withRouter(Audio);