import React, {Component} from "react";
import { Link } from 'react-router-dom';
import { global } from './../config.js';
import withRouter from './withRouter';

import ReactPlayer from 'react-player/lazy'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import NotFound from './NotFound'
import NotConnect from './NotConnect'
import Loading from './Loading'

class Video extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.params.id,
            item: {},
            loading: true,
            error: false,
            notConnect: false
        };

        this.item = this.item.bind(this)
    }

    componentDidMount(){
        this.item()
    }

    item() {
        
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        fetch(global.endpoint_api + '/items/' + this.state.id, requestOptions)
        .then(res => res.json().then(
            (result) => {

                if (res.status === 200 && !result.error) {
                    this.setState({
                        item: result,
                        loading: false
                    });
                } else {
                    if (result.error) {
                        this.setState({
                            error: result.error.message
                        });
                    } else {
                        this.setState({
                            error: "Произошла ошибка. Пожалуйста, попробуйте позже."
                        });
                    }
                }
                this.setState({
                    loading: false
                });
            }
        )).catch(err => {
            console.log(err)
            this.setState({
                notConnect: true
            });
        })

    }


    render() {

        let { item, loading } = this.state

        if (this.state.notConnect)
            return (<NotConnect />)

        return (
            <>
                {item.video && item.video !== null && !loading && (
                    <Row className="justify-content-md-center mb-4 mt-3">
                        <Col md={9} lg={6}>
                            <Link to={"/i/" + this.state.id}><span aria-hidden="true">&larr;</span> Назад</Link>
                        </Col>
                    </Row>
                )}

                <Row className="justify-content-md-center mb-4">
                    
                    <Col md={9} lg={6}>
                        
                        {loading && (<Loading />)}

                        {(!item.video || item.video === null) && !loading && (<NotFound />)}

                        {item.video && item.video !== null && !loading && (
                            <div className="mb-5">
                                <div className="mb-4">
                                    <div className="d-flex align-items-start">
                                        <Image src="/images/icons/video.svg" width={40} aria-hidden="true"/>
                                        <div className="ms-3">
                                            <h5 className="mb-1">Русский жестовый язык с субтитрами</h5>
                                            <p className="m-0 truncate-text-lines" aria-hidden="true">Видео</p>
                                        </div>
                                    </div>
                                    {item.video.startsWith("https://rutube.ru/video/") ? (
                                        <div className="frame-container mb-3 mt-3">
                                            <iframe
                                                className="frame-video"
                                                title="video"
                                                src={item.video.replace("https://rutube.ru/video/", "https://rutube.ru/play/embed/")}
                                                webkitAllowFullScreen
                                                mozallowfullscreen
                                                allowFullScreen>
                                            </iframe>
                                        </div>
                                    ) : (
                                        <ReactPlayer
                                            className="mt-4"
                                            controls={true}
                                            url={item.video}
                                            width="100%"
                                            height="100%"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default withRouter(Video);