
import React, {Component} from "react";
import withRouter from './withRouter';

import BeatLoader from "react-spinners/BeatLoader";

class Loading extends Component {

    render() {

        return (
            <div className="prevent-select d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <BeatLoader
                        size={10}
                    />
                </div>
            </div>
        )

    }
}

export default withRouter(Loading);
                        