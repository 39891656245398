import React, {Component} from "react";
import { Link } from 'react-router-dom';
import { global } from './../config.js';
import withRouter from './withRouter';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import NotFound from './NotFound'
import NotConnect from './NotConnect'
import Loading from './Loading'

class Text extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.params.id,
            item: {},
            loading: true,
            error: false,
            notConnect: false
        };

        this.item = this.item.bind(this)
        this.addHidden = this.addHidden.bind(this)
    }

    componentDidMount(){
        this.item()
    }

    addHidden() {
        var img = document.body.getElementsByTagName("img");
        var i = 0;
        while (i < img.length) {
            img[i].setAttribute("aria-hidden", "true");
            i++;
        }
    }

    item() {
        
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        fetch(global.endpoint_api + '/items/' + this.state.id, requestOptions)
        .then(res => res.json().then(
            (result) => {

                if (res.status === 200 && !result.error) {
                    this.setState({
                        item: result,
                        loading: false
                    }, () => this.addHidden());
                } else {
                    if (result.error) {
                        this.setState({
                            error: result.error.message
                        });
                    } else {
                        this.setState({
                            error: "Произошла ошибка. Пожалуйста, попробуйте позже."
                        });
                    }
                }
                this.setState({
                    loading: false
                }, () => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                });
            }
        )).catch(err => {
            console.log(err)
            this.setState({
                notConnect: true
            });
        })

    }


    render() {

        let { item, loading } = this.state

        if (this.state.notConnect)
            return (<NotConnect />)

        return (
            <>
                {item.text && item.text !== null && !loading && (
                    <Row className="justify-content-md-center mb-4 mt-3">
                        <Col md={9} lg={6}>
                            <Link to={"/i/" + this.state.id}><span aria-hidden="true">&larr;</span> Назад</Link>
                        </Col>
                    </Row>
                )}
                <Row className="justify-content-md-center mb-4">
                    
                    <Col md={9} lg={6}>
                        
                        {loading && (<Loading />)}

                        {!item.text && !loading && (<NotFound />)}

                        {item.text && item.text !== null && !loading && (
                            <div className="mb-5">
                                        <div className="mb-4">
                                            <div dangerouslySetInnerHTML={{__html: item.text}} />
                                        </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default withRouter(Text);